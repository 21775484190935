import { elb } from '@elbwalker/walker.js';
import { ModalProvider } from '@sumup/circuit-ui';
import App, { type AppContext, type AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { I18nProvider } from '../components/I18nProvider';
import type { ServerErrorProps } from '../lib/errors';
import type { I18nPageProps } from '../types/i18n';

import NextError from './_error';

import { useHistoryOverride } from 'hooks/useHistoryOverride';
import { optionalQueryParam } from 'lib/query';
import '@sumup/design-tokens/light.css';
import '@sumup/circuit-ui/styles.css';
import { initAnalytics } from 'services/analytics/walker';

type CustomAppProps = Omit<NextAppProps, 'pageProps'> & {
  pageProps: I18nPageProps & ServerErrorProps;
  backURL?: string;
  userIp: string;
};

const MyApp = ({
  Component,
  pageProps: { messages, serverError, ...pageProps },
  backURL,
  userIp,
}: CustomAppProps) => {
  const router = useRouter();

  // NOTE: currently used by step up flow
  useHistoryOverride(backURL);

  useEffect(() => {
    initAnalytics({
      locale: router.locale as string,
      clientId: router.query?.client_id as string,
      userIp,
    });

    try {
      elb('walker run');
    } catch {
      // Fail gracefully
    }
  }, [router.locale, router.query?.client_id, router.pathname, userIp]);

  const component = serverError ? (
    <NextError {...serverError} />
  ) : (
    <Component {...pageProps} />
  );

  return (
    <I18nProvider initialMessages={messages}>
      <Head>
        <title key="title">SumUp</title>
      </Head>
      <ModalProvider>{component}</ModalProvider>
    </I18nProvider>
  );
};

MyApp.getInitialProps = async (context: AppContext) => {
  const backURL = optionalQueryParam(context.ctx.query.back_url);
  const forwardedFor = context.ctx.req?.headers['x-forwarded-for'] as string;
  const userIp =
    forwardedFor?.split(', ')[0] ||
    context.ctx.req?.socket?.remoteAddress ||
    '';

  const ctx = await App.getInitialProps(context);
  return { ...ctx, backURL, userIp };
};

export default MyApp;
